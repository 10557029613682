<template>
  <div class="product-availability">
    <h5
      v-tv:product="'availability'"
    />
    <div class="prod-d-buybox__wrapper">
      <div class="prod-d-buybox__row">
        <div
          class="prod-d-buybox__label"
          v-tv:product="'materialNumber'"
        />
        <SKUContainer
          :class="'prod-d-buyboy__value'"
          :product="product"
        />
      </div>
      <div class="prod-d-buybox__row">
        <div
          class="prod-d-buybox__label"
          v-tv:cart="'hsCode'"
        />
        <span
          class="prod-d-buyboy__value"
          v-text="getHsCode"
        />
      </div>
      <div class="prod-d-buybox__row">
        <div
          class="prod-d-buybox__label"
          v-tv:product="'successor'"
        />
        <div
          class="prod-d-buybox__value product_successor"
        >
          <nuxt-link
            :to="this.localePath('/categories/' + getCategoryUrlFromTechnicalName(product.productDesign, this.$i18n.locale) + '/products/' + product.successor, this.$i18n.locale)"
            v-if="product.successor"
          >
            <span v-text="product.successor" />
          </nuxt-link>
          <span
            v-else
            v-text="'-'"
          />
        </div>
      </div>
      <div
        class="prod-d-buybox__row"
        v-if="hasShowAlternativePlants"
      >
        <div class="prod-d-buybox__label">
          <div v-tv:product="'warehouse'" />
          <a
            v-show="hasMultipleWarehouses && hasRight(USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY)"
            href="#"
            @click.prevent="openPopup()"
            class="change-warehouse"
          >
            (<span v-tv:product="'change'" />)
          </a>
        </div>
        <div
          class="prod-d-buyboy__value"
          v-text="getWarehouseName"
        />
      </div>
      <div
        class="prod-d-buybox__row"
        v-if="!(isDiscontinuedWithStockZero)"
      >
        <div
          class="prod-d-buybox__label"
          v-tv:product="'stock'"
        />
        <div class="prod-d-buyboy__value">
          <QuantityDisplay
            :quantity="getStock"
            :unit="getStockUnit"
          />
        </div>
      </div>
      <div class="prod-d-buybox__row">
        <div
          class="prod-d-buybox__label"
          v-tv:product="'status'"
        />
        <div
          class="prod-d-buyboy__value"
          @mouseenter="statusVisibility(true)"
          @mouseleave="statusVisibility(false)"
          :class="{'is_limited_availability': isLimitedAvailability, 'is_discontinued': isDiscontinued}"
        >
          {{ getStatus }}
          <AlertWarning
            class="product-availability-warning"
            v-if="isLimitedAvailability"
          />
        </div>
        <ToolTip
          :show-tool-tip="showLimitedAvailabilityToolTip"
          tool-tip-position="bottom"
          arrow-position-vertically="top"
          arrow-position-horizontally="right"
        >
          <div class="tooltip-message product-page-tooltip">
            <span v-tv:status="'Important Legal Note Header'" />
            <p
              class="tooltip-availability-text"
            >
              <span v-tv:status="'Important Legal Note Text P1'" />
              <span v-tv:status="'Important Legal Note Text P2'" />
              <span v-tv:status="'Important Legal Note Text P3'" />
              <span v-tv:status="'Important Legal Note Text P4'" />
            </p>
          </div>
        </ToolTip>
      </div>
    </div>
  </div>
</template>

<script>
import {storeToRefs} from 'pinia'
import {PLANT, LIMITED_AVAILABILITY_STATUS, DISCONTINUED_STATUS, CONFIGURATION_KEYS} from '~/assets/js/constants'
import QuantityDisplay from '~/components/QuantityDisplay'
import ToolTip from '~/components/ToolTip.vue'
import AlertWarning from '~/assets/icons/alert_warning.svg?component'
import SKUContainer from '~/components/SKUContainer'
import categoryUrls from '~/mixins/category_urls'
import {useConfigurationDataStore} from '@/store/configuration-data'

export default {
  name: 'ProductAvailability',
  mixins: [categoryUrls],
  components: {
    QuantityDisplay,
    ToolTip,
    AlertWarning,
    SKUContainer
  },
  props: {
    currentStock: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    warehouse: {
      type: Object,
      default() {
        return null
      }
    },
    hasMultipleWarehouses: {
      type: Boolean

    },
    product: {
      type: Object,
      default() {
        return null
      }
    },
    status: {
      type: Number,
      default() {
        return null
      }
    },
    statusLabel: {
      type: String,
      default() {
        return null
      }
    },
    isDiscontinuedWithStockZero: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup() {
    const {configurations} = storeToRefs(useConfigurationDataStore())
    return {
      configurations
    }
  },
  data() {
    return {
      showLimitedAvailabilityToolTip: false
    }
  },
  computed: {
    hasShowAlternativePlants() {
      const hasShowAlternativePlants = this.configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_ALTERNATIVE_PLANTS_KEY)
      return hasShowAlternativePlants?.value === 'True'
    },
    getStatus() {
      return this.$tv(this.statusLabel, 'status')
    },
    isLimitedAvailability() {
      return this.status === LIMITED_AVAILABILITY_STATUS
    },
    isDiscontinued() {
      return this.status === DISCONTINUED_STATUS
    },
    getStock() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) ? this.currentStock?.quantity : null
    },
    getStockUnit() {
      return this.currentStock?.unit
    },
    getWarehouseName() {
      if (this.warehouse?.isDefault) {
        return this.$tv(this.product?.defaultPlantCode, PLANT.PLANT_TRANSLATION_KEY)
      }
      else {
        return this.$tv(this.warehouse?.alternativePlant, PLANT.PLANT_TRANSLATION_KEY)
      }
    },
    getHsCode() {
      return this.product.commodityCode ?? '-'
    },
    hasProductBuyPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY)
    },
    hasProductRequestPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
    }
  },
  methods: {
    statusVisibility(visibility) {
      if (visibility && this.isLimitedAvailability) {
        this.showLimitedAvailabilityToolTip = true
      } else {
        this.showLimitedAvailabilityToolTip = false
      }
    },
    openPopup() {
      this.$emit('open-popup')
    }
  }
}
</script>

<style lang="scss">
a.change-warehouse {
  text-transform: none;
  margin-left: .5rem;
}

.prod-d-buyboy__value {
  font-weight: map-get($base-text-label-medium, font-weight);
  font-size: map-get($base-text-label-medium, font-size );
}

.prod-d-buybox__label {
  font-size: map-get( $base-text-value-medium, font-size );
  font-weight: map-get($base-text-value-medium, font-weight );
  line-height: map-get($base-text-value-medium, line-height );
}

.is_limited_availability {
  @include breakpoint-up($xl) {
    justify-content: flex-end;
    max-height: 1.125rem;
  }

  align-items: center;
  color: $color__text--error;
  display: flex;
  cursor: pointer;
}

.prod-d-buyboy__value.is_discontinued {
  color: $color__text--error;
}

.tooltip-message.product-page-tooltip .tooltip-availability-text {
  font-weight: $base-text-font-weight-light;
  font-size: $base-text-font-size;
}

.product-availability-warning.icon--svg {
  font-size: 1.25rem;
}
</style>
