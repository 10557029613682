<template>
  <client-only>
    <div class="buy-box__section">
      <h3
        class="h5"
        v-tv:product="'buy-article'"
      />

      <div class="prod-d-buyarticle__wrapper">
        <div
          class="prod-d-buyarticle__row"
        >
          <div
            class="prod-d-buyarticle__label"
            v-tv:product="'quantity'"
          />
          <div
            class="prod-d-buyarticle__input"
          >
            <ProductQuantity
              :current-quantity="quantity"
              :adjustments="adjustments"
              :quantity-validation="quantityValidation"
              :show-quantity-tooltip="quantityTooltip"
              @tooltipVisibility="handleQuantityTooltipVisibility"
              @quantityChange="handleQuantityChange"
              @startEditing="handleStartEditing"
              :disabled="disableInputs || (!hasProductBuyPermission && !hasProductRequestPermission && !hasAvailabilityPermission && !hasPricePermission)"
            />
          </div>
        </div>
        <div
          class="prod-d-buyarticle__row dicontinued-status-message"
          :class="{'visible': isRequestedQuantityLargerThenStock && !isDiscontinuedWithStockZero}"
        >
          <span
            class="dicontinued-status-message--text"
            v-tv:product="'discontinuedStatusMessage'"
          />
        </div>
        <div
          class="prod-d-buyarticle__row"
        >
          <div class="prod-d-buyarticle__label availability-date">
            <span v-tv:product="'availabilityDate'" />
          </div>
          <div
            class="prod-d-buyarticle__input calendar"
          >
            <ProductDatepicker
              :current-date="date"
              :date-validation="validateAvailabilityDate"
              :show-date-tooltip="dateTooltip && !isLimitedAvailability && !(isDiscontinued && isQtyHigherStock)"
              :show-only-first-entry="isDiscontinued"
              :stock-schedule-rows="stockScheduleRows"
              @dateChanged="handleDateChange"
              :disabled="disableInputs || (!hasProductBuyPermission && !hasProductRequestPermission && !hasAvailabilityPermission) || isLimitedAvailability"
              @tooltipVisibility="handleDateTooltipVisibility"
            />
          </div>
        </div>
        <div
          class="prod-d-buyarticle__row partial-qty-available"
          :class="{'visible': avIsValidationTypeWarning && !isLimitedAvailability && !(isDiscontinued && isQtyHigherStock)}"
        >
          <span
            class="partial-qty-available--text"
            v-tv:product="'partialQtyAvailable'"
          />
          <span class="partial-qty-available--arrow">
            <ArrowUp />
          </span>
        </div>
        <div
          class="prod-d-buyarticle__row prod-d-buyarticle__warnings_errors"
          v-if="hasErrorsOrWarnings"
        >
          <div
            class="prod-d-buyarticle__errors"
            v-for="(error, index) in roundedItemWarningsErrorsFiltered?.errors"
            :key="index"
          >
            <div v-html="$tv(error.messageKey, 'product.error')" />
          </div>
          <div
            class="prod-d-buyarticle__warnings"
            v-for="(warning, index) in roundedItemWarningsErrorsFiltered.warnings"
            :key="index"
          >
            <div v-html="$tv(warning.messageKey, 'product.warning')" />
          </div>
        </div>
        <div
          class="prod-d-buyarticle__row prod-d-buyarticle__warnings_errors"
          v-if="isDiscontinuedWithStockZero"
        >
          <div class="prod-d-buyarticle__warnings">
            <div v-tv:product="'noLongerBuyable'" />
          </div>
        </div>
        <div
          class="prod-d-buyarticle__prices"
          v-if="!isDiscontinuedWithStockZero && !isRequestOnly"
        >
          <div class="prod-d-buyarticle__row total">
            <div
              class="prod-d-buyarticle__label"
              v-tv:product="'total'"
            />
            <div class="prod-d-buyarticle__value">
              <Price
                :price="getPriceTotal"
                :hide-price-request="isLimitedAvailability"
              />
            </div>
          </div>
          <div class="prod-d-buyarticle__row per-pieces">
            <div
              class="prod-d-buyarticle__label"
              v-tv:product="keyPrice100"
            />
            <div class="prod-d-buyarticle__value">
              <Price
                :price="getPricePer100"
                :hide-price-request="isLimitedAvailability"
              />
            </div>
          </div>
          <div class="prod-d-buyarticle__row per-pieces">
            <div
              class="prod-d-buyarticle__label"
              v-tv:product="keyPrice1"
            />
            <div class="prod-d-buyarticle__value">
              <Price
                :price="getPricePerPiece"
                :hide-price-request="isLimitedAvailability"
              />
            </div>
          </div>
          <div
            class="prod-d-buyarticle__row min-position"
          >
            <div
              class="prod-d-buyarticle__label"
              v-tv:product="'minPositionValue'"
            />
            <div class="prod-d-buyarticle__value">
              <Price
                :price="getMinPositionValue"
                :hide-price-request="true"
                :on-request-if-zero="false"
              />
            </div>
          </div>
        </div>
        <div class="prod-d-buyarticle__row action-buttons">
          <!--          <button-->
          <!--            class="k-button k-button-md k-button-solid k-button-solid-base button&#45;&#45;big-text"-->
          <!--            v-tv:product="'add-to-order-list'"-->
          <!--            @click="handleAddToOrderList"-->
          <!--            :disabled="disableInputs || disableButtons"-->
          <!--          />-->
          <kbutton
            class="button--big-text"
            :theme-color="'primary'"
            @click="handleAddToCart"
            :disabled="lockAddToCart || disableInputs || disableButtons || (!hasProductBuyPermission && !hasProductRequestPermission) || (isLimitedAvailability && isQtyHigherStock)"
          >
            {{ addButtonText }}
          </kbutton>
        </div>
        <div
          v-if="isLimitedAvailability && isQtyHigherStock"
          class="prod-d-buyarticle__limited-availability-text error"
          v-tv:cart="'productNotBuyable'"
        />
        <div
          v-if="isDiscontinued && isQtyHigherStock"
          class="prod-d-buyarticle__limited-availability-text"
          v-tv:cart="'productOnlyRequestable'"
        />
      </div>
    </div>
  </client-only>
</template>

<script>
import {storeToRefs} from 'pinia'
import ProductQuantity from '~/components/product-detail/buy-box/ProductQuantity'
import ProductDatepicker from '~/components/product-detail/buy-box/ProductDatepicker'
import Price from '~/components/PriceF'
import {getNextDate} from '~/assets/js/utils'
import {USER_RIGHTS_MAPPING,
  CUSTOMER_SETTING_KEYS,
  EXCLUDABLE_WARNING_KEYS,
  LIMITED_AVAILABILITY_STATUS,
  ITEMS,
  DISCONTINUED_STATUS,

  PRODUCT_UNITS} from '~/assets/js/constants'
import ArrowUp from '~/assets/icons/arrow-up.svg?component'
import {Button} from '@progress/kendo-vue-buttons'
import {getSettingByKey} from '~/assets/js/settings'
import {useCustomerSettingsStore} from '@/store/customer-settings'

export default {
  name: 'ProductBuyArticle',
  components: {kbutton: Button, Price, ProductDatepicker, ProductQuantity, ArrowUp},
  props: {
    product: {
      type: Object,
      required: true
    },
    currentStock: {
      type: Object,
      required: false,
      default() {
        return {
          quantity: 0
        }
      }
    },
    validateAvailabilityDate: {
      type: Object,
      default() {
        return {}
      }
    },
    currentQuantity: {
      type: Number,
      default() {
        return 1
      }
    },
    currentDate: {
      type: Date,
      default() {
        return getNextDate()
      }
    },
    stockScheduleRows: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    price: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    disableInputs: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    disableButtons: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    status: {
      type: Number,
      default() {
        return null
      }
    },
    adjustments: {
      type: Object,
      default() {
        return {}
      }
    },
    quantityValidation: {
      type: Object,
      default() {
        return {
          type: ITEMS.VALIDATION_TYPES.WARNING,
          hasAdjustments: false,
          quantityWarnings: []
        }
      }
    },
    roundedItemWarningsErrors: {
      type: Object,
      default() {
        return {
          warnings: [],
          errors: []
        }
      }
    },
    units: {
      type: String,
      default() {
        return 'PCE'
      }
    },
    isDiscontinuedWithStockZero: {
      type: Boolean,
      default() {
        return false
      }
    },
    isRequestOnly: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup () {
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())
    return {
      currentCustomerSettings
    }
  },
  data() {
    return {
      quantity: this.currentQuantity,
      date: this.currentDate,
      dateTooltip: false,
      quantityTooltip: false,
      lockAddToCart: false,
      stockQuantity: 0,
      lastInputQuantity: Number.MAX_SAFE_INTEGER
    }
  },
  computed: {
    isLimitedAvailability() {
      return this.status === LIMITED_AVAILABILITY_STATUS
    },
    isDiscontinued() {
      return this.status === DISCONTINUED_STATUS
    },
    isQtyHigherStock() {
      return this.currentQuantity > this.stockQuantity
    },
    hasPricePermission() {
      return this.hasRight(USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)
    },
    hasAvailabilityPermission() {
      return this.hasRight(USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY)
    },
    getPricePer100: function() {
      return this.hasPricePermission ? this.price?.pricePer100 : null
    },
    getPricePerPiece() {
      if (!this.price || !this.hasPricePermission) {
        return null
      }

      let price = this.price?.pricePer100 ?? null
      if (price) {
        price /= 100
      }
      return price ?? 0
    },
    getMinPositionValue() {
      return this.hasPricePermission ? this.price?.minimumOrderLineValue : null
    },
    getPriceTotal: function() {
      return this.hasPricePermission ? this.price?.price : null
    },
    hasProductBuyPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY)
    },
    hasProductRequestPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
    },
    avIsValidationTypeWarning() {
      return this.validateAvailabilityDate?.type === ITEMS.VALIDATION_TYPES.WARNING
    },
    hasErrorsOrWarnings() {
      return this.roundedItemWarningsErrorsFiltered?.errors?.length > 0 || this.roundedItemWarningsErrorsFiltered?.warnings?.length > 0
    },
    roundedItemWarningsErrorsFiltered() {
      if (this.isDiscontinued && this.product &&
          this.lastInputQuantity < this.product.packagingUnit &&
          !this.hasRoundingEnabled) {
        const warnings = this.roundedItemWarningsErrors?.warnings ?? []

        const index = warnings.findIndex(warning => warning.messageKey === EXCLUDABLE_WARNING_KEYS.QuantityRounded)
        if (index > -1) {
          warnings.splice(index, 1)
          return {...this.roundedItemWarningsErrors, warnings: warnings}
        }
      }
      return this.roundedItemWarningsErrors
    },
    hasRoundingEnabled() {
      const switchOffRoundingSettings = getSettingByKey(this.currentCustomerSettings, CUSTOMER_SETTING_KEYS.SwitchOffRounding)
      return switchOffRoundingSettings?.customerSettingValue === '1' ? true : false
    },
    isRequestedQuantityLargerThenStock() {
      if (this.isQtyHigherStock && this.isDiscontinued) {
        return true
      }
      return false
    },
    keyPrice100() {
      if (this.units === PRODUCT_UNITS.MTR || this.units === PRODUCT_UNITS.M ) {
        return 'per-100-m'
      } else if (this.units === PRODUCT_UNITS.PCE || this.units === PRODUCT_UNITS.PCS) {
        return 'per-100-pcs'
      }
      return 'per-100-kg'
    },
    keyPrice1() {
      if (this.units === PRODUCT_UNITS.MTR || this.units === PRODUCT_UNITS.M) {
        return 'per-1-m'
      } else if (this.units === PRODUCT_UNITS.PCE || this.units === PRODUCT_UNITS.PCS) {
        return 'per-1-pc'
      }
      return 'per-1-kg'
    },
    addButtonText() {
      if ((this.isDiscontinued && this.isQtyHigherStock) || this.isDiscontinuedWithStockZero || this.isRequestOnly) {
        return this.$tv('add-as-request', 'product')
      } else {
        return this.$tv('add-to-cart', 'product')
      }
    }
  },
  methods: {
    // handleAddToOrderList() {
    //   this.$emit('addToOrderList')
    // },
    handleAddToCart() {
      if (!this.editingQuantity) {
        if ((this.isDiscontinued && this.isQtyHigherStock) || this.isDiscontinuedWithStockZero) {
          this.$emit('addToCart', {markedAsRequest: true})
        } else if (this.isLimitedAvailability && this.isQtyHigherStock) {
          this.$toast.error(this.$tv('failed', 'cart'))
        } else {
          this.$emit('addToCart')
        }
      }
    },
    handleStartEditing(qty) {
      if (qty > (this.currentStock?.quantity ?? 0) && this.isLimitedAvailability) {
        this.lockAddToCart = true
      } else {
        this.lockAddToCart = false
      }
    },
    handleQuantityChange(quantity) {
      this.editingQuantity = false
      this.lastInputQuantity = quantity
      this.$emit('quantityChanged', quantity)
    },
    handleDateChange(date) {
      this.$emit('dateChanged', date)
    },
    handleDateTooltipVisibility(visible) {
      this.dateTooltip = visible
    },
    handleQuantityTooltipVisibility(visible) {
      this.quantityTooltip = visible
      if (visible && this.quantityValidation.hasAdjustments) {
        this.$emit('adjustmentsPopupOpened')
      }
    }
  },
  watch: {
    currentQuantity: {
      immediate: true,
      handler() {
        this.quantity = this.currentQuantity
      }
    },
    currentStock: {
      immediate: true,
      handler(val) {
        this.stockQuantity = val && val?.quantity !== 0 ? val.quantity : this.stockQuantity
      }
    },
    currentDate: {
      immediate: true,
      handler() {
        this.date = this.currentDate
      }
    }
  }
}
</script>

<style lang="scss">
.prod-d-buyarticle__wrapper {
  .prod-d-buyarticle__limited-availability-text {
    margin-top: 1rem;
    font-size: .9rem;

    &.error {
      color: $color__text--error;
    }
  }

  .prod-d-buyarticle__row {
    .prod-d-buyarticle__input {
      flex: 0 0 11.0625rem;
    }

    .prod-d-buyarticle__errors,
    .prod-d-buyarticle__warnings {
      font-size: $base-text-font-size;
    }

    .prod-d-buyarticle__errors {
      color: $color__text--error;
    }

    .prod-d-buyarticle__warnings {
      color: $color__default;
    }

    .k-button {
      font-size: map-get($base-text-button, font-size);
      line-height: map-get($base-text-button , line-height );
      font-weight: map-get($base-text-button , font-weight );
      min-width: 0;
      width: calc(50% - 1rem);
    }

    &.total {
      margin-bottom: .5rem;

      > div {
        font-size: map-get($base-text-label-large, font-size );
        line-height: $base-text-line-height;
        font-weight: bolder;
      }

      .prod-d-buyarticle__label {
        text-transform: none;
      }

      .prod-d-buyarticle__value {
        font-size: map-get($base-text-label-large, font-size );

      }
    }

    &.per-pieces,
    &.min-position {
      font-weight: lighter;

      .prod-d-buyarticle__label {
        text-transform: none;
      }

      .prod-d-buyarticle__value {
        font-size: map-get($base-text-value-medium, font-size )
      }
    }

    &.action-buttons {
      justify-content: flex-end;
    }

    &.partial-qty-available,
    &.dicontinued-status-message {
      color: $color__text--error;
      place-content: center flex-end;
      font-size: $base-text-font-size;
      transition: max-height $transition-time__default;
      max-height: 2rem;

      &:not(.visible) {
        max-height: 0;
        margin-top: 0;
        margin-bottom: 0;
        opacity: 0;
      }

      .partial-qty-available--text,
      .dicontinued-status-message--text {
        text-align: right;
        line-height: normal;
      }

      .partial-qty-available--arrow {
        flex: 0 0 2.5rem;
        display: inline-flex;
        justify-content: center;
        font-size: 1rem;
      }
    }
  }

  .tool-tip__wrapper {
    right: 0;
  }
}
</style>
