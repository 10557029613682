<template>
  <div>
    <div
      v-if="!newpdp"
      id="ProductBuyBox"
      :class="{
        'buy-box--inactive': !isActive || inProgress || loading,
        'buy-box--loading': inProgress || loading,
      }"
    >
      <product-availability
        :current-stock="currentStock"
        :warehouse="warehouse"
        :status="status"
        :status-label="statusLabel"
        :has-multiple-warehouses="hasMultipleWarehouses"
        :is-discontinued-with-stock-zero="isDiscontinuedWithStockZero"
        :product="minifiedProduct"
        @open-popup="!disableInputs && openWarehousePopup()"
        class="buy-box__section"
      />
      <product-buy-article
        :product="product"
        :current-stock="currentStock"
        :disable-inputs="disableInputs"
        :validate-availability-date="validateAvailabilityDate"
        :current-quantity="quantityChosen"
        :current-date="availabilityDate"
        :disable-buttons="minMaxDateIncorrect"
        :stock-schedule-rows="stockScheduleRows"
        :is-request-only="isRequestOnly"
        :is-discontinued-with-stock-zero="isDiscontinuedWithStockZero"
        :price="price"
        :status="status"
        :adjustments="adjustments"
        :quantity-validation="validateQuantity"
        :rounded-item-warnings-errors="warningsAndErrors"
        :units="units"
        @quantityChanged="handleQuantityChange"
        @dateChanged="handleDateChange"
        @addToCart="handleAddToCart"
        @adjustmentsPopupOpened="loadQuantityAdjustments"
        class="buy-box__section"
      />

      <div
        class="prod-d-buybox__row price-scales"
        v-if="!isDiscontinuedWithStockZero && !isRequestOnly"
      >
        <tool-tip
          v-if="showPriceScalesTooltip"
          :show-tool-tip="showPriceScalesTooltip"
          tool-tip-position="top"
          arrow-position-vertically="bottom"
          arrow-position-horizontally="right"
          @close="showPriceScalesTooltip = false"
          class="price-scales__tooltip"
          :class="{
            'price-scales__tooltip--incl-recommended-price':
              isRecommendedPriceVisible,
          }"
          allow-close
        >
          <div
            v-if="showPriceScalesTooltip"
            class="tooltip-message"
          >
            <span
              class="price-scales__headline"
              v-tv:product="'price-scales'"
            />
            <product-price-scales
              :rows="priceScales"
              :is-loading="priceScalesLoading"
              :show-recommended-price="isRecommendedPriceVisible"
              :dynamic-height="true"
            />
          </div>
        </tool-tip>
        <a
          href="javascript:void(0)"
          class="price-scales header buy-box__section"
          @click.prevent="openPriceScalesTooltip()"
          v-if="hasRight(USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)"
        >
          <span
            class="price-per-piece h4"
            v-tv:product="'price-scales'"
          />
          <span
            :title="$tv('gridIconTooltip', 'cart')"
            class="grid-icon"
          >
            <EditorItemList />
          </span>
        </a>
      </div>
      <MountedTeleport :to="'.modal-container'">
        <PopupBase
          :show="showAvailableWarehousesModal"
          :level="1"
          @close-pop-up="showAvailableWarehousesModal = false"
          :size="popupSizeMedium"
        >
          <PopupAvailableWarehouses
            @warehouse="warehouseChoosen"
            :warehouse="warehouse"
            :product="product"
            :quantity="quantityChosen"
            :date="availabilityDate"
            :reload-warehouses="showAvailableWarehousesModal"
            @close-pop-up="showAvailableWarehousesModal = false"
          />
        </PopupBase>
      </MountedTeleport>
      <div class="buy-box-mask">
        <div class="buy-box-mask__layer" />
        <AppLoadingIndicator />
        <span
          v-show="!isActive"
          class="buy-box-mask__text"
          v-tv:product="'productNotBuyable'"
        />
      </div>
    </div>
    <div
      v-if="newpdp"
      class="newpdp-popup"
    >
      <h5
        v-text="getProductText"
      />
      <div class="newpdp-popup-wrapper">
        <!-- brand is missing -->
        <ProductDetails
          class="pdp-box"
          :product="productWithCategoryDesigns"
          :brand="brand"
          :download-files="productDownloads"
          :product-name="productDescription"
          @open-variants="this.showVariants = true"
        />
        <!-- :loading currently always false, till warehouse switch is implemented and the component can trigger reload -->
        <AvailabilityPricing
          :product="buyboxProduct"
          :loading="false"
          @addToCart="handleAddToCart"
        />
      </div>
    </div>
    <PopupSuccessorVariants
      :show="showVariants"
      :sku-array="variantsAndSuccessor"
      @close-variants="showVariants = false"
      :second-layer="true"
    />
  </div>
</template>

<script>
import ProductAvailability from './buy-box/ProductAvailability'
import ProductBuyArticle from './buy-box/ProductBuyArticle'
import PopupBase from '~/components/popup/PopupBase'
import PopupAvailableWarehouses from '~/components/popup/PopupAvailableWarehouses'
import {usePandaService} from '@/composables/panda_service'
import {usePurchaseService} from '@/composables/purchase_service'
import {
  ADDITEM_STATUS,
  DISCONTINUED_STATUS,
  REQUEST_ONLY_STATUS,
  CUSTOMER_SETTING_KEYS,
  PLANT,
  ITEMS,
  FILENAME_REGEX,
  POPUP,
  PROPERTY_SCOPE_NAMES,
  ASSET_TYPES
} from '~/assets/js/constants'
import {storeToRefs} from 'pinia'
import {getNextDate, getMaxDate} from '~/assets/js/utils'
import availabilitiesMixin from '~/mixins/availabilities'
import {useToastificationHelper} from '@/composables/toastification_helper'
import AppLoadingIndicator from '~/components/loading-indicators/app-loading-indicator.vue'
import {getSettingByKey} from '~/assets/js/settings'
import EditorItemList from '~/assets/icons/editor_format_list_bulleted.svg?component'
import ToolTip from '~/components/ToolTip.vue'
import {useCartStore} from '@/store/cart'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useUserStore} from '@/store/user'
import MountedTeleport from '~/components/MountedTeleport'
import ProductPriceScales from './buy-box/ProductPriceScales.vue'
import ProductDetails from '~/components/product-detail/bb_components/ProductDetails'
import AvailabilityPricing from '~/components/product-detail/bb_components/AvailabilityPricing'
import {
  getMappedProduct,
  getMappedProductCategoryImages
} from '~/assets/js/utils/product_attributes_mapping'
import {getEComText} from '~/assets/js/utils/product'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {useProductService} from '@/composables/product_service'

export default {
  components: {
    PopupBase,
    PopupAvailableWarehouses,
    ProductAvailability,
    ProductBuyArticle,
    AppLoadingIndicator,
    EditorItemList,
    ToolTip,
    MountedTeleport,
    ProductPriceScales,
    ProductDetails,
    AvailabilityPricing
  },
  mixins: [availabilitiesMixin],
  name: 'ProductBuyBox',
  props: {
    productData: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  setup() {
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())
    const {deliveryAddressUuid} = storeToRefs(useCartStore())
    const {loadedCustomerUuid} = storeToRefs(useUserStore())
    const {getAvailabilities, getMaterialPrices, getPriceScales} =
      usePandaService()
    const {addItems} = useCartStore()
    const {getProductQuantityAdjustments, getProductQuantityRounding} =
      usePurchaseService()

    const {experimentalMode} = storeToRefs(useConfigurationDataStore())
    const {getProductBySku} = useProductService()

    return {
      currentCustomerSettings,
      deliveryAddressUuid,
      loadedCustomerUuid,
      addItems,
      getAvailabilities,
      getMaterialPrices,
      getPriceScales,
      getProductQuantityAdjustments,
      getProductQuantityRounding,
      experimentalMode,
      getProductBySku
    }
  },
  data() {
    return {
      adjustments: {
        adjustments: null,
        loading: false,
        lastRequestedQuantity: -1
      },
      quantityChosen: 1,
      availabilityDate: getNextDate(),
      warehouse: null,
      showAvailableWarehousesModal: false,
      price: null,
      availability: {},
      inProgress: false,
      priceScales: [],
      priceScalesLoading: false,
      changeTimeoutHandle: null,
      changeStateTimeoutHandle: null,
      warningsAndErrors: null,
      firstQuantityRoundingDone: false,
      showPriceScalesTooltip: false,
      trackedHotjar: false,
      showVariants: false,
      transitionEnter: 400,
      transitionExit: 200,
      popupSizeMedium: POPUP.Size.medium,
      product: null
    }
  },
  mounted() {
    this.product = this.productData
    this.$eventbus.on('emit-warehouse-change', async (alternativePlantCode) => {
      const newProductData = await this.getProductBySku(this.product.sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, alternativePlantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE)
      // eslint-disable-next-line vue/no-mutating-props
      this.product = newProductData
    })
  },
  unmounted() {
    this.$eventbus.off('emit-warehouse-change')
  },
  methods: {
    async handleQuantityChange(quantity) {
      this.quantityChosen = quantity
      if (this.changeTimeoutHandle) {
        clearTimeout(this.changeTimeoutHandle)
      }
      this.changeTimeoutHandle = setTimeout(this.loadData, 200)
    },
    async handleDateChange(date) {
      if (date < getNextDate()) {
        date = getNextDate()
      }
      if (date > getMaxDate()) {
        date = getMaxDate()
      }
      this.availabilityDate = date
      if (this.changeTimeoutHandle) {
        clearTimeout(this.changeTimeoutHandle)
      }
      this.changeTimeoutHandle = setTimeout(this.loadData, 200)
    },
    async handleAddToCart(payload) {
      const markedAsRequest = payload?.markedAsRequest ?? false
      const newpdp = payload?.newpdp ?? false
      const quantity = payload?.quantity ?? 1
      const availabilityDate = payload?.availabilityDate ?? null
      const warehouse = payload?.warehouse ?? null

      if (
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
      ) {
        this.$toast.error(this.$tv('failed', 'cart'))
      }

      if (this.changeTimeoutHandle) {
        clearTimeout(this.changeTimeoutHandle)
      }
      this.inProgress = true

      try {
        let item = {
          sku: this.product?.sku,
          quantity: newpdp ? quantity : this.quantityChosen,
          availabilityDate: newpdp ? availabilityDate : this.availabilityDate
        }

        if (markedAsRequest) {
          item.markedAsRequest = markedAsRequest
        }

        if (newpdp) {
          if (
            warehouse &&
            !warehouse.isDefault &&
            warehouse.alternativePlantGuid
          ) {
            item.alternativePlantUuid = warehouse.alternativePlantGuid
          }
        } else {
          if (
            this.warehouse &&
            !this.warehouse.isDefault &&
            this.warehouse.alternativePlantGuid
          ) {
            item.alternativePlantUuid = this.warehouse.alternativePlantGuid
          }
        }

        let res = await this.addItems({
          itemsToAdd: [item],
          region: this.$globalization.getRegion(),
          locale: this.$i18n.locale
        })
        if (res && res.length > 0 && res[0].status === ADDITEM_STATUS.SUCCESS) {
          this.$toast.success(
            useToastificationHelper().translateAndFormat(
              {
                messageKey: markedAsRequest
                  ? 'itemAddedWithQtyRequest'
                  : 'itemAddedWithQtyBuy',
                messageValues: {
                  sku: this.product?.sku,
                  quantity: res[0]?.addedQty
                }
              },
              'cart'
            )
          )
          this.$emit('productSuccessfullyAdded')
        } else {
          this.inProgress = false
          if (res && res.length > 0 && res[0].errors.length > 0 && !res[0].errors.some(e => e.messageKey === 'Cart_Max_Items_Exceeded')) {
            this.$toast.error(this.$tv('failed', 'cart'))
          }
          return
        }
      } catch {
        this.$toast.error(this.$tv('failed', 'cart'))
      }

      this.inProgress = false
    },
    warehouseChoosen(data) {
      this.warehouse = data.warehouse
      this.quantityChosen = data.quantity
      this.availabilityDate = data.availabilityDate
      this.availability = data.availability
      const plant = this.getPlant
      this.$emit('reloadProduct', plant)
    },
    async loadData() {
      await this.$nextTick()
      this.inProgress = true
      let quantitySuggested = null

      if (!this.isSwitchOffRoundingEnabled && this.isActive && this.product) {
        quantitySuggested = await this.getProductQuantityRounding(
          this.product?.sku,
          this.quantityChosen
        )
      }

      if (!this.firstQuantityRoundingDone && quantitySuggested) {
        this.quantityChosen = quantitySuggested.itemRoundedQuantity
        this.firstQuantityRoundingDone = true
      } else {
        if (
          quantitySuggested?.itemRoundedQuantity !==
          quantitySuggested?.itemRequestedQuantity
        ) {
          this.quantityChosen = quantitySuggested.itemRoundedQuantity
          this.warningsAndErrors = {
            warnings: quantitySuggested.itemWarnings,
            errors: quantitySuggested.itemErrors
          }
        } else if (this.quantityChosen < this.product?.minimumOrderQuantity) {
          this.quantityChosen = this.product.minimumOrderQuantity
        } else {
          this.warningsAndErrors = {warnings: [], errors: []}
        }
      }

      await this.$nextTick()
      this.price = null
      this.availability = {}
      if (this.product) {
        // check if we haven't tracked yet, are not on a product-page and hotjar is installed at all, hacky but working
        if (
          !this.trackedHotjar &&
          window &&
          !window.document?.querySelector?.('.product-page') &&
          typeof window['hj'] !== 'undefined'
        ) {
          try {
            window.hj(
              'stateChange',
              `${window.location.pathname}#buy-box-${this.product?.sku}`
            )
            this.trackedHotjar = true
          } catch {
            // just fortifying against vendor code errors
          }
        }
      }
      if (this.product && this.isActive && !this.isRequestOnly) {
        await Promise.all([this.loadPrice(), this.loadAvailabilities()])
      }
      this.inProgress = false
    },
    async loadQuantityAdjustments() {
      if (this.adjustments.lastRequestedQuantity !== this.quantityChosen) {
        this.adjustments.adjustments = null
        this.adjustments.loading = true
        await this.$nextTick()
        this.adjustments.lastRequestedQuantity = this.quantityChosen
        const res = await this.getProductQuantityAdjustments(
          this.product?.sku,
          this.quantityChosen
        )

        if (res) {
          this.adjustments.adjustments = res
          this.adjustments.adjustments.suggestedQuantityText = this.$tv({
            messageKey: 'quantityAndUnit',
            messageValues: {
              quantity: this.adjustments.adjustments.suggestedQuantity,
              unit: this.$tv(
                this.availability?.currentStock?.unit,
                'packageUnits'
              )
            }
          })
        }
        this.adjustments.loading = false
      }
    },
    async loadPrice() {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)) {
        let requestingQuantity = this.quantityChosen
        let price = await this.getMaterialPrices(
          this.product?.sku,
          requestingQuantity,
          true
        )
        // make sure to use latest request
        if (requestingQuantity === this.quantityChosen) {
          this.price = price
        }
      }
    },
    async loadAvailabilities() {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY)) {
        const plant = this.getPlant
        let requestingQuantity = this.quantityChosen
        let availability = await this.getAvailabilities(
          this.product?.sku,
          this.quantityChosen,
          this.availabilityDate,
          plant,
          true
        )
        // make sure to use latest request
        if (requestingQuantity === this.quantityChosen) {
          this.availability = availability
        }
      }
    },
    async loadPriceScales() {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)) {
        this.priceScalesLoading = true
        await this.$nextTick()
        this.priceScales = []
        if (this.product && this.isActive) {
          this.priceScales = (await this.getPriceScales(this.product?.sku)).priceScales
        }
        this.priceScalesLoading = false
      }
    },
    async openWarehousePopup() {
      this.showAvailableWarehousesModal = true
    },
    async handleStateChanged() {
      if (this.product) {
        this.quantityChosen = Math.max(this.product.packageUnit || 1, 1)
        this.warehouse = {
          ...PLANT.DEFAULT_PLANT_OBJECT,
          plant: this.product?.defaultPlantCode
        }
        this.loadData()
        this.priceScales = []
        this.showPriceScalesTooltip = false
      }
    },
    async openPriceScalesTooltip() {
      this.showPriceScalesTooltip = !this.showPriceScalesTooltip
      if (this.showPriceScalesTooltip && this.priceScales.length === 0) {
        this.loadPriceScales()
      }
    },
    getSortOrder(assetType) {
      if (assetType === 'CAD') {
        return 1
      }
      return 0
    }
  },
  computed: {
    newpdp() {
      return true
      // TODO #32699 follow up , delete all newpdp references
      // return this.$route.query.newpdp === '1' || this.experimentalMode;
    },
    getProductText() {
      return getEComText(this.$tv, this.product)
    },
    validateQuantity() {
      let result = {
        type: ITEMS.VALIDATION_TYPES.NONE,
        hasAdjustments: false,
        quantityWarnings: []
      }

      if (this.price?.potentialAdjustment) {
        result.type = ITEMS.VALIDATION_TYPES.INFO
        result.hasAdjustments = true
      }

      return result
    },
    isActive() {
      return (
        !this.product ||
        (this.product?.requestable ?? false) ||
        (this.product?.buyable ?? false)
      )
    },
    isRequestOnly() {
      return REQUEST_ONLY_STATUS.includes(this.product?.status)
    },
    stateChanged() {
      return `${this.$auth.loggedIn}${this.loadedCustomerUuid}${this.product?.buyable}${this.deliveryAddressUuid}${this.product?.requestable}`
    },
    disableInputs() {
      return !this.isActive || this.inProgress
    },
    minMaxDateIncorrect() {
      const current = this.availabilityDate
      return (
        current < getNextDate().setHours(0, 0, 0, 0) ||
        current > getMaxDate().setHours(23, 59, 59, 0)
      )
    },
    isRecommendedPriceVisible() {
      const showRecommendedSetting = getSettingByKey(
        this.currentCustomerSettings,
        CUSTOMER_SETTING_KEYS.IsRecommSalesPriceVisible
      )
      return showRecommendedSetting?.customerSettingValue === '1' ?? false
    },
    currentStock() {
      return this.availability?.currentStock ?? null
    },
    status() {
      return this.product?.status ?? null
    },
    statusLabel() {
      return this.product?.statusLabel ?? null
    },
    isSwitchOffRoundingEnabled() {
      const switchOffRounding = getSettingByKey(
        this.currentCustomerSettings,
        CUSTOMER_SETTING_KEYS.SwitchOffRounding
      )
      return switchOffRounding?.customerSettingValue === '1' ?? false
    },
    getPlant() {
      return this.warehouse &&
        !this.warehouse.isDefault &&
        this.warehouse.alternativePlant
        ? this.warehouse.alternativePlant
        : null
    },
    productSuccessorSku() {
      return this.product?.salesAreas?.length > 0
        ? this.product.salesAreas[0].successorSku
        : null
    },
    minifiedProduct() {
      return {
        sku: this.product?.sku,
        customerSku: this.product?.customerMappings?.number,
        successor: this.productSuccessorSku,
        design: this.product?.productDesign,
        commodityCode: this.product?.commodityCode,
        defaultPlantCode: this.product?.defaultPlantCode
      }
    },
    units() {
      return this.product?.variant?.unit
    },
    isDiscontinuedWithStockZero() {
      return (
        this.product?.status === DISCONTINUED_STATUS &&
        this.availability?.currentStock?.quantity === 0
      )
    },
    hasProductBuyPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY)
    },
    hasProductRequestPermission() {
      return this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
    },
    productDescription: function () {
      return this.product?.locales[this.$i18n.locale] ?? ''
    },
    buyboxProduct() {
      return getMappedProduct(this.product)
    },
    productWithCategoryDesigns: function () {
      return getMappedProductCategoryImages(this.product, null, false)
    },
    productDownloads() {
      let result = []
      let categoryAssets = []
      if (this.categoryContents?.locale === this.$i18n.locale) {
        if (this.categoryContents?.Assets.length > 0) {
          categoryAssets = this.categoryContents.Assets.map((c) => ({
            assetType: c.AssetType,
            lookupKey: c.AssetURI,
            languageShortCode: this.$i18n.locale,
            sortOrder: 1
          }))
        } else if (this.categoryContents?.Downloads.length > 0) {
          categoryAssets = this.categoryContents.Downloads.map((d) => ({
            assetType: d.AssetType,
            lookupKey: d.File.url,
            languageShortCode: this.$i18n.locale,
            label:
              d.AssetType === ASSET_TYPES.ProductDataSheet
                ? this.$tv('ProductDataSheet', 'product.assetLink', d.Label)
                : d.Label,
            sortOrder: 1
          }))
        }
      }

      if (this.product?.assets) {
        result = this.product.assets.filter(
          (asset) => asset.languageShortCode === this.$i18n.locale
        )
        let presentAssetTypes = result.map((asset) => asset.assetType)
        result = result.concat(
          this.product.assets.filter(
            (asset) =>
              asset.languageShortCode === this.$i18n.defaultLocale &&
              !presentAssetTypes.includes(asset.assetType)
          )
        )
        if (
          categoryAssets.some(
            (asset) =>
              asset.assetType &&
              asset.assetType.toLowerCase() ===
                ASSET_TYPES.ProductDataSheet.toLowerCase()
          )
        ) {
          result = result.filter(
            (asset) =>
              !asset.assetType ||
              asset?.assetType?.toLowerCase() !== 'productdatasheet'
          )
        }
        result = result.map((asset) => ({
          ...asset,
          // alt text is to be set to the filename to identify materials in the download section, the filename is extracted from the lookupKey (#28924)
          altText:
            asset.label ||
            (asset.lookupKey &&
            FILENAME_REGEX.test(asset.lookupKey) &&
            asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              ? decodeURI(
                asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              )
              : null),
          sortOrder: this.getSortOrder(asset.assetType)
        }))
      }
      result = result.concat(categoryAssets)
      return result.length > 0
        ? result.sort((a, b) => a.sortOrder - b.sortOrder)
        : result
    },
    variantsAndSuccessor() {
      const variants = (this.$deproxy(this.product?.otherVariants) ?? []).map(v => ({...v, isSuccessor: false}))
      const successor = this.product?.salesAreas.map(s => {
        // eslint-disable-next-line eqeqeq
        return s.successorSku != null ? {sku: s.successorSku, isSuccessor: true} : null
      })
      let result = null
      if (successor && variants) {
        result = [{sku: this.product?.sku}, ...successor, ...variants]
      }
      else if (successor) {
        result = [{sku: this.product?.sku}, ...successor]
      }
      else if (variants) {
        result = [{sku: this.product?.sku}, ...variants]
      }
      else {
        result = [{sku: this.product?.sku}]
      }
      // eslint-disable-next-line eqeqeq
      return result.filter(vs => vs != null)
    }
  },
  watch: {
    stateChanged: {
      immediate: true,
      async handler() {
        if (this.changeStateTimeoutHandle) {
          clearTimeout(this.changeStateTimeoutHandle)
        }
        this.changeStateTimeoutHandle = setTimeout(
          this.handleStateChanged,
          100
        )
      }
    },
    productData: {
      immediate: true,
      handler() {
        this.product = this.productData
      }
    }
  }
}
</script>

<style lang="scss">
.popup__wrapper {
  width: fit-content;
  overflow: auto;

  .popup__content a {
    z-index: 2001;
  }
}

[id='ProductBuyBox'] {
  padding: $default-margin-padding-width;

  @include breakpoint-down($md) {
    &:not(.buy-box-in-popup) {
      position: relative;
    }
  }

  &.buy-box--inactive .buy-box__section {
    filter: blur(.25rem);
  }

  .buy-box-mask {
    position: absolute;
    inset: 0;
    display: none;
    justify-content: center;
    align-items: center;

    &__layer {
      opacity: .75;
      background-color: $color__white;
      position: absolute;
      inset: 0;
      z-index: 3;
    }

    &__text {
      color: $color__primary;
      font-size: 1.5rem;
      text-align: center;
      z-index: 3;
      padding: 1rem;
    }

    .loading-indicator {
      display: none;
    }

    &.loading-mask {
      .buy-box-mask__layer {
        background-color: $color__white;
      }
    }
  }

  &.buy-box--inactive .buy-box-mask,
  &.buy-box--loading .buy-box-mask {
    display: flex;
  }

  &.buy-box--loading .buy-box-mask .buy-box-mask__text {
    display: none;
  }

  &.buy-box--loading .buy-box-mask .loading-indicator {
    display: block;
  }

  .price-scales .grid-icon .icon--svg {
    width: 1.75rem;
    color: $color__primary;
    float: right;
  }

  .prod-d-buybox__row.price-scales {
    margin-top: 1rem;
  }

  .price-scales.header {
    width: 100%;
    text-decoration: none;
  }
}

.popup__content:has(.buy-box-in-popup) {
  padding-top: 1rem;

  .pdp-box {
    box-shadow: $buybox-figma-boxshadow;

    .gallery-list {
      display: none;
    }
  }
}

.popup {
  .newpdp-popup {
    background: $color__white;
    padding: 0;

    h5 {
      margin: 0 0 1rem;
    }

    &__slide__variants {
      display: none;
    }

    .showSlide {
      display: block;
    }

    .newpdp-popup-wrapper {
      display: flex;
      gap: 1rem;
    }
  }

  @include breakpoint-down($lg) {
    &.popup__buy-box .popup__wrapper {
      max-width: 25rem;
    }

    &.popup__buy-box-new .popup__wrapper {
      max-width: 39rem;

      .newpdp-popup-wrapper {
        flex-direction: column;

        .successor-variants {
          flex-basis: 100%;
        }

        .product-details-component {
          width: 100%;
        }

        [id='newProductBuyBox'] {
          width: calc(100% - 2rem);
        }
      }
    }
  }

  @include breakpoint-down($md) {
    &.popup__buy-box-new .popup__wrapper .newpdp-popup > * > * {
      margin: 2.5rem 0;
    }
  }

  &.popup__buy-box-new .popup__wrapper {
    overflow: hidden;
  }

  @include breakpoint-up($lg) {
    &.popup__buy-box-new .popup__wrapper {
      max-width: 78rem;

      .popup-successorVariants {
        .popup .popup__wrapper {
          width: auto;
          max-width: 58rem;
        }
      }

      .newpdp-popup {
        &__slide__variants {
          min-width: max-content;
        }

        > * > * {
          flex-direction: row;
        }

        .product-details-wrapper {
          width: calc(50% - .5rem);
        }

        [id='newProductBuyBox'] {
          width: calc(50% - .5rem);
        }
      }
    }
  }

  &.popup__buy-box-new .popup__wrapper & :not(.second-layer) {
    .popup__content {
      padding: 0;
    }
  }

  [id='ProductBuyBox'] {
    padding: 0;
  }
}
</style>
